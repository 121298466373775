<template>
  <div>
    <h5 class="mb-3">
      <div v-if="zip" class="d-flex align-items-center">
        <CButton size="sm" color="info" :disabled="zipAdded"
          @click="() => {
            $emit('add:zip', zip);
            this.zipAdded = true;
          }"
        >
          <CIcon name="cil-plus" />
        </CButton>
        <span class="ml-2">
          Zip code: <strong>{{ zip }}</strong>, Locality: <strong>{{ locality }}</strong>
          (~{{ haversineDistance | FixedNumber }} km)
        </span>
      </div>

      <div v-else id="msg" class="py-1">Click on the location where you want to get the zip code!</div>
    </h5>

    <div id="map"></div>
  </div>
</template>

<script>
export default {
  name: "map-restaurant-delivery-areas",
  props: {
    zip_codes: { default: null, type: Array },
    restaurant_name: { default: null, type: String },
    restaurant_lat: { default: null, type: Number },
    restaurant_lng: { default: null, type: Number },
  },
  data() {
    return {
      map: null,
      markers: [],
      // circles: [],
      rectangles: [],
      zip: null,
      locality: null,
      haversineDistance: null,
      zipAdded: false
    };
  },

  computed: {
    zipCodes: {
      get() {
        return this.zip_codes;
      },
      set(newValue) {
        this.$emit("update:zip_codes", newValue);
      },
    },
  },

  watch: {
    zip() {
      this.zipAdded = false;
    },
  },

  mounted() {
    if (this.zipCodes) {
      this.showOnMap();
    }
  },

  methods: {
    initMap() {
      var vm = this;

      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        draggableCursor: 'crosshair'
      });

      // Click on map and get zip code
      this.map.addListener("click", function (event) {
        let el = document.getElementById('msg');
        const geocodingService = new google.maps.Geocoder();

        geocodingService.geocode({ latLng: event.latLng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              for (var i = 0; i < results[0].address_components.length; i++) {
                var addressType = results[0].address_components[i].types[0];

                if (addressType === "locality") {
                  vm.locality = results[0].address_components[i].long_name;
                }

                if (addressType === "postal_code") {
                  vm.zip = results[0].address_components[i].long_name;

                  vm.haversineDistance = vm.$globalFunc.haversineDistance(
                    { lat: vm.restaurant_lat, lng: vm.restaurant_lng },
                    { lat: event.latLng.lat(), lng: event.latLng.lng() }
                  );
                  return;
                }
              }
              if (!vm.zip) { el.innerHTML = "No results found!"; }
            } else {
              el.innerHTML = "No results found!";
            }
          } else {
            el.innerHTML = `Geocoding failed with status ${status}`;
          }
        });
      });
    },

    showOnMap() {
      if (!this.map) {
        this.initMap();
      }

      this.deleteMarkers();
      // this.deleteCircles();
      this.deleteRectangles();

      // Restaurant
      if (!!this.restaurant_lat && !!this.restaurant_lng) {
        var restaurant_position = {
          lat: parseFloat(this.restaurant_lat),
          lng: parseFloat(this.restaurant_lng),
        };

        this.markers.push(
          new google.maps.Marker({
            map: this.map,
            position: restaurant_position,
            label: this.restaurant_name.charAt(0),
            title: this.restaurant_name,
            icon: "/img/map/restaurant.png",
          })
        );

        this.map.setCenter(restaurant_position);
      }

      // Delivery areas
      this.zipCodes.forEach((area, index) => {
        window.setTimeout(async () => {
          // Marker
          this.addMarker(area);

          // Bounds
          if (area.bounds) {
            const { id, zip_code_id, ...bounds } = area.bounds
            this.addRectangle(bounds);
          } else {
            await this.getBounds(area);
          }
        }, index * 200);
      });
    },

    deleteMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },
    deleteCircles() {
      for (let i = 0; i < this.circles.length; i++) {
        this.circles[i].setMap(null);
      }
      this.circles = [];
    },
    deleteRectangles() {
      for (let i = 0; i < this.rectangles.length; i++) {
        this.rectangles[i].setMap(null);
      }
      this.rectangles = [];
    },

    addMarker(area) {
      this.markers.push(
        new google.maps.Marker({
          label: area.zcCity.charAt(0),
          title: area.zcCity,
          animation: google.maps.Animation.DROP,
          // icon: "/img/map/active-pin.png",

          position: {
            lat: parseFloat(area.zcLat),
            lng: parseFloat(area.zcLng),
          },
          map: this.map,
        })
      );
    },
    addRectangle(bounds) {
      this.rectangles.push(
        new google.maps.Rectangle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: "#FF0000",
          fillOpacity: 0.15,

          bounds: bounds,
          map: this.map,
        })
      );
    },

    async getBounds(area) {
      const country = this.authCountries.find((c) => c.value == area.zcCountryId);

      let geocoder = new google.maps.Geocoder();
      var vm = this;
      geocoder.geocode({ componentRestrictions: { country: country ? country.iso2 : "BE", postalCode: area.zcZip }}, function (results, status) {
          if (status == "OK") {
            const geometry = results[0].geometry;

            vm.addRectangle(geometry.bounds);
            vm.saveBounds(area.id, geometry.bounds.toJSON());
          } else {
            console.log(`Geocode was not successful ${area.zcZip} for the following reason: ${status}`);
          }
        }
      );
    },

    async saveBounds(zip_code_id, bounds) {
      await this.$axios.post(this.$backend.ZIP_CODES.STORE_ZIP_CODE_BOUNDS.replace("{zip_code_id}", zip_code_id), bounds)
        .then((response) => {
          this.zipCodes.find(zc => zc.id == zip_code_id).bounds = response.data
        })
    },

    async getBounds____(area) {
      let geocoder = new google.maps.Geocoder();
      var vm = this;
      geocoder.geocode({ componentRestrictions: { country: "BE", postalCode: area.zcZip }}, function (results, status) {
      // geocoder.geocode({ componentRestrictions: { country: "BE" }, 'address': `${area.zcCity}, ${area.zcZip}` }, function (results, status) {
          if (status == "OK") {
            console.log(results[0]);
            return;
            const geometry = results[0].geometry;
            const { lat, lng } = geometry.location.toJSON();

            vm.addRectangle(geometry.bounds);

            if (lat != area.zcLat || lng != area.zcLng) {
              area.zcLat = lat;
              area.zcLng = lng;
              console.log('farklı');
            }

            vm.addMarker(area);

            vm.saveBounds(area.id, geometry.bounds.toJSON());
          } else {
            console.log(`Geocode was not successful ${area.zcZip} for the following reason: ${status}`);
          }
        }
      );
    },
  },
};
</script>

<style>
#map {
  height: 600px;
  width: 100%;
}
</style>
